<template class="flex-grow-1">
  <div style="height: 100%;">
    <b-sidebar id="sidebar-1" style="background: #FEFEFE;" title="Sidebar" width="512px" left no-header visible sidebar-class="left-info-panel" z-index="1010">
      <b-container class="pt-3 d-flex flex-column justify-content-start" style="background: #FEFEFE;height: 100vh">
        <div class="d-flex flex-row justify-content-between">
          <button class="btn-park-outline" @click="$router.push('/')" v-if="!selectedObject">
            <div class="info-text">
              <img src="../assets/icons/left_arrow.png">
              <span class="ml-2"> Выйти из режима создания территории</span>
            </div>
          </button>

          <button class="btn-park-outline" @click="selectedObject=null" v-else>
            <div class="info-text">
              <img src="../assets/icons/left_arrow.png">
              <span class="ml-2"> Вернуться к результату</span>
            </div>
          </button>
          <img src="../assets/icons/logo_itmo.png">
        </div>
        <template v-if="!selectedObject">
        <h1 class="very-large-title-text mt-5 mb-5">Новый парк</h1>

        <div class="d-flex flex-row justify-content-start mb-5">
          <div class="title-text mr-5" style="padding-left: 15px; cursor: pointer" @click="goToEditor">Редактор</div>
          <div class="large-title-text"
               style="background: #E7EEE7;border-radius: 10000px;padding: 8px; padding-left: 15px;padding-right: 15px">
            Результаты генерации</div>
        </div>

        <div class="tep-primary mb-2 d-flex flex-column">
          <div class="text-left primary-tep-text mb-2">
            Площадь участка
            </div>
          <div class="d-flex flex-row justify-content-between">
            <div class="primary-tep-value">{{areaHa}} га</div>
            <div class="primary-tep-secondary-value">{{areaMeters}} м<sup>2</sup></div>
          </div>
        </div>

        <div class="d-flex flex-row mb-2" style="gap: 10px">
          <div class="tep-primary d-flex flex-column justify-content-between" style="width: 228px">
            <div class="primary-tep-text text-left mb-2"> Плотность дорожной сети</div>
            <div class="primary-tep-value text-left">{{roadDensity}} км/км<sup>2</sup></div>
          </div>
          <div class="tep-primary d-flex flex-column justify-content-between" style="width: 228px">
            <div class="primary-tep-text text-left mb-2">Площадь дорожек с твердым покрытием</div>
            <div class="primary-tep-value text-left">{{roadArea}} м<sup>2</sup></div>
          </div>
        </div>

        <div class="d-flex flex-wrap" style="gap: 10px">
          <div class="tep-secondary" style="width: 228px" v-for="tep of this.secondaryParams" :key="tep.name">
            <div class="d-flex flex-row align-content-center">
              <div class="request-circle mr-2" :style="{'background-color': getColorForObjectTag(tep.name)}"></div>
              <div class="text-left secondary-tep-text mb-2">
                {{getTextForObjectTag(tep.name)}}
              </div>
            </div>
            <div class="d-flex flex-row justify-content-between">
              <div class="secondary-tep-value">{{tep.primaryValue.toFixed(2)}} м<sup>2</sup></div>
              <div class="secondary-tep-secondary-value" v-if="tep.secondaryValue">{{tep.secondaryValue}}</div>
            </div>
          </div>
        </div>

        </template>
        <template v-else>
          <ParkObjectComponent :selectedObject="selectedObject"></ParkObjectComponent>
        </template>

         </b-container>
    </b-sidebar>

    <editable-map ref="map"
                  editable
                  :zoom="zoom"
                  :maxZoom="18"
                  :center="center"
                  @click="mapClicked"
                  class="flex-grow-1"
                  :options="{attributionControl: false}"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-control-attribution position="bottomright"></l-control-attribution>
      <l-geo-json :geojson="parkBounds" v-if="parkBounds" :options-style="{color: '#ff0000', fillOpacity: 0, dashArray: '10,5'}" pane="boundsPane"></l-geo-json>
      <l-geo-json :geojson="resultObjects" v-if="resultObjects" :options-style="styleFunction" :options="{onEachFeature: onEachFeature}" pane="objectsPane"></l-geo-json>
      <l-geo-json :geojson="resultRoads" v-if="resultRoads" :options-style="styleFunction" :options="{onEachFeature: onEachFeature}" pane="roadsPane"></l-geo-json>
      <l-control-scale position="bottomright" :maxWidth="200" :imperial="false" :metric="true"></l-control-scale>
    </editable-map>

  </div>
</template>

<script>
import {geoJSON, latLng} from "leaflet";
import {EditableMap} from "vue2-leaflet-editable";
import {LControlAttribution, LControlScale, LGeoJson, LTileLayer} from "vue2-leaflet";
import MapAPI from "@/mixins/MapAPI";
import ParkObjectComponent from "@/components/ParkObjectComponent";
import FeatureStyles from "@/mixins/FeatureStyles";

export default {
  name: "Result",
  components: {
    ParkObjectComponent,
    EditableMap,
    LTileLayer,
    LControlAttribution,
    LControlScale,
    LGeoJson
  },
  mixins: [
    MapAPI,
    FeatureStyles
  ],
  data() {
    return {
      parkBounds: null,
      parkTags: {},
      entryPointMarkers: [],
      resultObjects: null,
      resultRoads: null,
      taskId: "",
      resultEconomyParams: [],

      selectedObject: null,

      zoom: 15,
      center: latLng(59.939468, 30.308769),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }
  },

  mounted() {
    this.parkBounds = this.$route.params.parkBounds
    this.parkTags = this.$route.params.parkTags
    this.taskId = this.$route.params.taskId
    this.resultObjects = this.$route.params.resultObjects
    this.resultRoads = this.$route.params.resultRoads
    this.entryPointMarkers = this.$route.params.entryPointMarkers
    let mapObject = this.$refs.map.mapObject
    mapObject.createPane("objectsPane")
    mapObject.createPane("roadsPane")
    mapObject.createPane("boundsPane")

    mapObject.getPane("boundsPane").style.zIndex = 200
    mapObject.getPane("roadsPane").style.zIndex = 300
    mapObject.getPane("objectsPane").style.zIndex = 400


    let g = geoJSON({type: "FeatureCollection", features: [this.parkBounds]});
    mapObject.fitBounds(g.getBounds())

    this.getParkTEP(this.taskId).then(rz => {
      this.resultEconomyParams = rz.data.economyParams
    })
  },

  methods: {
    featureClicked(feature) {
      if (feature.properties.type === "entry_point") {
        // should not be clickable
        return;
      }
      this.selectedObject = feature;
    },
    mapClicked() {
      this.selectedObject = null;
    },

    styleFunction(feature) {
      switch (feature.properties.type) {
        case "road": return {
          fillColor: "#aaaaaa",
          color: "#565656",
          fillOpacity: 1,
          weight: 1
        };

        case "greenery":  return {
          fillColor: "#bad77b",
          color: "#76884d",
          fillOpacity: 1
        };
        case "multifunctional": return {
          fillColor: "#4fb8f1",
          color: "#336b8a",
          fillOpacity: 1
        };
        case "fountain": return {
          fillColor: "#6cb5dc",
          color: "#232d33",
          fillOpacity: 1
        };
        case "calm_rest":  return {
          fillColor: "#8AECA0",
          color: "#42704d",
          fillOpacity: 1
        };

        case "restaurant":  return {
          fillColor: "#FD9191",
          color: "#724242",
          fillOpacity: 1
        };

        case "playground":  return {
          fillColor: "#FFC38C",
          color: "#7c5e42",
          fillOpacity: 1
        };

        case "sports":  return {
          fillColor: "#FE9DF4",
          color: "#6c4268",
          fillOpacity: 1
        };

        case "dog_park":  return {
          fillColor: "#FF9882",
          color: "#5d372f",
          fillOpacity: 1
        };

        case "border": return {
          fillOpacity: 0,
          color: "#ff0000",
          dashArray: "10,5"
        };

        case "grass": return {
          fillColor: "#b7f8c4",
          color: "#53855d",
          weight: 0.5,
          fillOpacity: 1
        }

        case "bicycle_parking":
        case "parking": return {
          fillColor: "#8E9AF7",
          color: "#3b4067",
          fillOpacity: 1
        }

        case "amusement_park": return {
          fillColor: "#FF81B6",
          color: "#5d2e41",
          fillOpacity: 1
        }
      }
    },

    onEachFeature(feature, layer) {
      let popupText = this.getObjectName(feature)

      if (popupText) {
        layer.bindPopup(popupText)
      }
      let context = this;
      layer.on('click', (event) => {context.featureClicked(event.target.feature)})
    },

    goToEditor() {
      this.$router.push({
        name: 'editor',
        params: {
          parkGeometry: this.parkBounds,
          parkTags: this.parkTags,
          entryPointMarkers: this.entryPointMarkers
        }
      })
    },

    getTepById(tepId) {
      let tep = this.resultEconomyParams.find((ep) => ep.name === tepId)
      if (tep) {
        return tep.primaryValue
      }
      return 0.0
    }
  },

  computed: {
    areaHa() {
      return  (this.getTepById('total_area') / 10000.0).toFixed(2)
    },

    areaMeters() {
      return  (this.getTepById('total_area')).toFixed(0)
    },

    roadDensity() {
      return  (this.getTepById('road_density')).toFixed(2)
    },

    roadArea() {
      return  (this.getTepById('road_area')).toFixed(0)
    },

    secondaryParams() {
      return this.resultEconomyParams.filter((param) => {
        return param.primaryValue > 0 && param.name !== "total_area" && param.name !== "road_density" && param.name !== "road_area"
      })
    }
  }
}
</script>

<style scoped>
@import '../assets/styles/map.css';
@import '../assets/styles/editor.css';
@import '../assets/styles/viewer.css';
@import '../assets/styles/requests.css';
</style>