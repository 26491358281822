<template class="flex-grow-1">
  <div style="height: 100%">
    <div class="tb-toggle-panel d-flex flex-row align-items-center justify-content-around">
      <div @click="switchTbMarkers();" style="cursor: pointer">
        <div v-bind:class="checkedTB ? 'tb-toggle-active' : 'tb-toggle-passive'" >
          <img src="../assets/icons/marker.png">
        </div>
      </div>
      <div @click="switchTbMarkers();" style="cursor: pointer">
        <div v-bind:class="checkedTB ? 'tb-toggle-passive' : 'tb-toggle-active'">
          <img src="../assets/icons/marker_crossed.png">
        </div>

      </div>

    </div>

    <b-img :src="require('../assets/nckrMap.png')" class="nckr-logo"></b-img>
    <div class="map-panel about-panel title-text d-flex flex-row justify-content-around">
      <b-link class="hidden-link" to="/about">О проекте</b-link>
      <img src="@/assets/icons/right_arrow.png">
    </div>
    <div v-if="polygonInfoPopup == null && !generating && !error" class="map-info map-panel">
      <div class="d-flex align-items-stretch">
          <div>
          <div class="flex-fill info-text">
            <template v-if="!polygonDrawing">
              Этот сервис позволяет планировать наполнение рекреационных зон. Начертите границы зоны, чтобы получить рекомендации по планированию.
            </template>
            <template v-else>
              Последовательно отметьте на карте точки, чтобы очертить территорию. Для окончания ввода замкните линию, нажав на первую точку.
            </template>
          </div>
        </div>
        <div class="d-flex align-items-stretch">
          <button v-if="!polygonDrawing" class="btn-park-primary" @click="startPolygon">
            <div class="d-flex flex-row">
            <img width="18px" height="18px" src="@/assets/icons/pen.png">
            <div style="margin-left: 15px;color:#fefefe" class="title-text">Начертить</div>
            </div>
          </button>
          <button v-if="polygonDrawing" class="btn-park-outline " @click="clearPolygon">
            <div class="d-flex flex-row">
              <div class="title-text">Сбросить</div>
            </div>

          </button>
        </div>
      </div>
    </div>
    <editable-map ref="map"
                  editable
                  :zoom="zoom"
                  :maxZoom="18"
                  :center="center"
                  class="flex-grow-1"
                  :options="{attributionControl: false}"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-control-attribution position="bottomleft"></l-control-attribution>
      <l-control-scale position="bottomleft" :maxWidth="200" :imperial="false" :metric="true"></l-control-scale>
    </editable-map>
  </div>
</template>

<script>
import L, {latLng} from "leaflet";
import {LControlAttribution, LControlScale, LTileLayer} from 'vue2-leaflet';
import {EditableMap} from "vue2-leaflet-editable";
import {markercluster} from "leaflet.markercluster";
import MapAPI from "../mixins/MapAPI.js";

import pencilIcon from "@/assets/pencil.svg";
import areaIcon from "@/assets/area.svg";

// needed to keep import from removing by idea of eslint, this import has important side effects
//eslint-disable-next-line
let markerclusterv = markercluster

export default {
  name: "MapComponent.vue",
  components: {
    EditableMap,
    LTileLayer,
    LControlAttribution,
    LControlScale
  },
  mixins: [ MapAPI ],
  data() {
    // should be non-reactive as it explodes the vue memory and cpu usage
    this.tbRecords = {
      type: "FeatureCollection",
      features: []
    }
    return {
      pencilIcon: pencilIcon,
      areaIcon: areaIcon,
      loading: false,
      zoom: 15,
      center: latLng(59.939468, 30.308769),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      polygonDrawing: false,
      polygonName: '',
      polygonTags: {},
      polygonArea: 0.0,
      jsonResponse: '',
      generating: false,
      polygon: null,
      polygonInfoPopup: null,
      error: null,
      timerEnabled: false,
      timerCount: 0,
      estimatedTime: 0,
      tbMarkers: null,
      checkedTB: true,
    };
  },
  methods: {

    areaText(m2) {
      if (m2 > 10000) {
        return (m2 / 10000).toFixed(2) + ' га (' + Number(m2.toFixed(0)).toLocaleString('ru-RU') + ' м<sup>2</sup>)';
      } else {
        return Number(m2.toFixed(2)).toLocaleString('ru-RU') + ' м<sup>2</sup>';
      }
    },
    countTimer() {
      setTimeout(() => {
        this.timerCount++;
        if (this.timerEnabled) {
          this.countTimer()
        }
      }, 1000);
    },
    generate() {
      this.$router.push({
        name: 'editor',
        params: {
          parkGeometry: this.polygon.toGeoJSON(),
          parkTags: this.polygonTags
        }
      })
    },

    startPolygon() {
      let mapObject = this.$refs.map.mapObject;
      this.polygon = mapObject.editTools.startPolygon();

      mapObject.on('editable:editing', function (e) {
        e.layer.setStyle({
          color: 'Black',
          fillOpacity: 0.0,
          weight: 2,
          dashArray: "10,10"
        });
      });

      this.polygon.on('click', this.showPolygonPopup);
      this.polygon.options.color = '#17A2B8';
      this.polygonDrawing = true;
      this.$ga.event("Park", "start_drawing", "drawing")
    },
    clearPolygon() {
      this.$ga.event("Park", "clear_drawing", "drawing")
      let mapObject = this.$refs.map.mapObject;
      mapObject.editTools.stopDrawing();
      if (this.polygon) {
        this.polygon.remove()
      }
      this.polygon = null;
      this.requestId = null;
      this.generating = false;
      this.timerEnabled = false;
      this.error = null;
      this.polygonDrawing = false;
      if (this.polygonInfoPopup != null) {
        this.polygonInfoPopup.remove();
        this.polygonInfoPopup = null;
      }
      this.generatedPoints = [];

    },
    showPolygonPopup() {
      if (this.polygonInfoPopup != null || this.generating) return;
      let context = this;
      this.polygonInfoPopup = new L.Popup({
        closeOnClick: false
      }).setContent(
          this.popupSkeleton
      ).on("remove", function () {
        context.polygonInfoPopup = null;
      });
      this.$ga.event("Park", "finish_drawing", "drawing")
      this.checkShape(this.polygonGeoJSON).then(rz => {
        if (rz.data.code === 0) {
          this.polygonName = rz.data.name;
          this.polygonArea = rz.data.area;
          if (context.checkedTB) {
            this.polygonTags = rz.data.tags;
          }
          if (rz.data.area > 1000000) {
            this.polygonInfoPopup.setContent(this.areaTooBigText);
            const btn = document.getElementById("popupButton"); //kind of hack can't call vue code from raw content. todo: alternatives
            btn.onclick = function(e) {
              e.preventDefault();
              context.clearPolygon();
            };
          } else if (rz.data.area < 2000) {
            this.polygonInfoPopup.setContent(this.areaTooSmallText);
            const btn = document.getElementById("popupButton"); //kind of hack can't call vue code from raw content. todo: alternatives
            btn.onclick = function(e) {
              e.preventDefault();
              context.clearPolygon();
            };
          } else {
            this.polygonInfoPopup.setContent(this.popupText);
            const btn = document.getElementById("popupButton");
            btn.onclick = function(e) {
              e.preventDefault();
              context.polygon.disableEdit();
              context.generate();
            };
          }
        }
      });
      this.polygonInfoPopup.setLatLng(this.polygon.getBounds().getCenter());
      let mapObject = this.$refs.map.mapObject;
      mapObject.addLayer(this.polygonInfoPopup);
    },
    getCentroid(arr) {
      return arr.reduce(function (x, y) {
        return [ x[0] + y[0] / arr.length, x[1] + y[1] / arr.length ]
      }, [0, 0]);
    },

    invertPolygonCoords(geoJson) {
      let coordinates = geoJson.geometry.coordinates;
      for (let ringIdx = 0; ringIdx < coordinates.length; ++ringIdx) {
        for (let coordIdx = 0; coordIdx < coordinates[ringIdx].length; ++coordIdx) {
          let pointCoordinates = coordinates[ringIdx][coordIdx];
          if (!pointCoordinates) {
            // most likely empty polygon
            continue;
          }
          coordinates[ringIdx][coordIdx] = [pointCoordinates[1], pointCoordinates[0]];
        }
      }
      return geoJson;
    },
    switchTbMarkers() {
      this.checkedTB = !this.checkedTB;
      if (this.checkedTB){
        this.tbMarkers.addTo(this.$refs.map.mapObject);
       } else {
        this.$refs.map.mapObject.removeLayer(this.tbMarkers);
      }
    }
  },
  computed: {
    popupText() {
      let rz = '<div style="width: 300px;\n' +
          'height: 117px;\n" class="d-flex flex-column align-content-stretch">' +
          '<div class="large-title-text">' + this.polygonName + '</div>' +
           "<div class='gray-subtitle-text' style='margin-top: 4px; margin-bottom: 12px;flex-grow: 2'>" + this.areaText(this.polygonArea) + "</div>";

      rz += '<button id="popupButton" class="btn-park-secondary info-text">Перейти к редактированию</button>' +
          '</div>';
      return rz;
    },
    areaTooBigText() {
      return '<div class="error-label">Полигон слишком большой</div>' +
          '<div class="area-label" style="margin: 16px 0px"><img src="' + areaIcon + '"></img>' + this.areaText(this.polygonArea) + '</div>' +
          '<a href="#" class="popup-button disabled" disabled="disabled">Генерация невозможна</a>' +
          '<a href="#" id="popupButton" class="popup-button" style="margin-top: 10px">Очистить</a>'
    },
    areaTooSmallText() {
      return '<div class="error-label">Полигон слишком маленький</div>' +
          '<div class="area-label" style="margin: 16px 0px"><img src="' + areaIcon + '"></img>' + this.areaText(this.polygonArea) + '</div>' +
          '<a href="#" class="popup-button disabled" disabled="disabled">Генерация невозможна</a>' +
          '<a href="#" id="popupButton" class="popup-button" style="margin-top: 10px">Очистить</a>'
    },
    popupSkeleton() {
      return '<div class="b-skeleton b-skeleton-text b-skeleton-animate-wave" style="width: 100px"></div>' +
          '<div class="b-skeleton b-skeleton-text b-skeleton-animate-wave"></div>' +
          '<br><a href="#" class="popup-button disabled"><span class="spinner-border spinner-border-sm"></span></a>';
    },
    isPolygonEmpty() {
      let polygon = this.polygonGeoJSON;
      return !polygon.geometry.coordinates
          || polygon.geometry.coordinates.length < 1
          || !polygon.geometry.coordinates[0]
          || polygon.geometry.coordinates[0].length < 2;
    },

    /**
     * Swaps lonlat coord order to latlon which is expected by backend
     */
    polygonGeoJSON() {
      let geoJson = this.polygon.toGeoJSON()
      return this.invertPolygonCoords(geoJson)
    }
  },
  mounted() {
    let mapObject = this.$refs.map.mapObject;
    let context = this;

    mapObject.on('editable:drawing:end', function () {
      if (!context.isPolygonEmpty) {
        context.showPolygonPopup();
      } else {
        context.clearPolygon();
      }
    });
    mapObject.on("click", function() {
      context.clickedPoint = null;
      context.clickedPolygon = null;
      context.clickedDistance = null;
      context.clickedDistanceGeometry = null;
    });

    this.getTbRecords().then(rz => {
      if (rz.data.code === 0) {
        this.tbRecords = rz.data.records
        let map = this.$refs.map.mapObject
        //L.geoJSON(this.tbRecords).addTo(map)
        var markers = L.markerClusterGroup({
          spiderfyOnMaxZoom: true,
          showCoverageOnHover: false,
          zoomToBoundsOnClick: false,
          disableClusteringAtZoom: 17
        });
        for (let feature of this.tbRecords.features) {
          let marker = L.marker(L.latLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]));
          let requests = JSON.parse(feature.properties["requests"])
          let text = feature.properties["description"]
          let textWithSpans = ""
          for (let i = 0; i < text.length; ++i) {
            for (let request of requests) {
              if (request.startIdx === i) {
                textWithSpans += "<span style='color: white; background-color:" + this.getColorForObjectTag(request.tag) + "'>"
              }
              if (request.endIdx === i) {
                textWithSpans += "</span>"
              }
            }
            textWithSpans += text[i]
          }

          let popupText = "<div class='large-title-text' >" + feature.properties["name"] + "</div>"
          popupText += "<p class='info-text' style='margin-top:8px'>" + textWithSpans + "</p>"
          popupText += "<h4>"
          for (let tag of JSON.parse(feature.properties["tags"])) {
            popupText += "<span class='request-badge' style='margin-right: 10px'><span class='request-circle' style='background-color: " + this.getColorForObjectTag(tag) + "'></span>"
                + "<span class='info-text'>" + this.getTextForObjectTag(tag) + "</span> </span>"
          }
          popupText += "</h4>"
          marker.bindPopup(popupText, {maxWidth: 600})
          markers.addLayer(marker);
        }
        this.tbMarkers = markers
        map.addLayer(this.tbMarkers)
      }
    })
  }
}
</script>

<style>
@import '../assets/styles/map.css';
@import '../assets/styles/requests.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
sup {
  line-height: 1 !important;
}
.nckr-logo {
  position: absolute;
  left: 40px;
  bottom: 40px;
  z-index: 1001;
}

.about-panel {

  position: absolute;
  width: 155px;
  height: 48px;
  right: 16px;
  bottom: 16px;
}

.tb-toggle-passive {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 8px;

  width: 52px;
  height: 40px;
}

.tb-toggle-active {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 8px;

  width: 52px;
  height: 40px;

  /* Green */

  background: #CDEC8A;
  border-radius: 1000px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.popup-button {
  display: block;
  width: 100%;
  height: 35px;
  padding: 8px 16px;
  text-align: center;
  border-radius: 50px;
  background-color: #17A2B8;
  color: white !important;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
}
.popup-button.disabled {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.12);
}

h4 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px !important;
  color: #7E8B96;
  display: flex;
  align-self: stretch;
}

.area-label {
  font-weight: 400;
  font-size: 12px !important;
  color: #4E5A61;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.error-label {
  color: #ff3333;
  margin: 6px 0px 0px 0px;
  font-weight: 700;
}
.area-label > img {
  margin-right: 6px;
  margin-top: 3px;
}


.popup-button {
  display: block;
  width: 100%;
  height: 35px;
  padding: 8px 16px;
  text-align: center;
  border-radius: 50px;
  background-color: #17A2B8;
  color: white !important;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
}
.popup-button.disabled {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.12);
}

.tb-toggle-panel {
  z-index: 1000 !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;

  position: absolute;
  width: 112px;
  height: 48px;
  right: 16px;
  top: 16px;

  background: rgba(255, 255, 255, 0.84);
  /* Blur stroke color */

  border: 1.5px solid rgba(233, 235, 233, 0.44);
  /* Shadows */

  box-shadow: 0px 6px 16px rgba(212, 218, 220, 0.64);
  backdrop-filter: blur(3px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 1000px;
}
</style>