import Axios from '../axios'

const MapAPI = {
    methods: {
        checkShape: function(vertices) {
            return Axios.post(process.env.VUE_APP_API_URL + `/map/checkShape`, vertices);
        },
        getTbRecords: function() {
            return Axios.get(process.env.VUE_APP_API_URL + `/map/tbRecords`);
        },

        generatePark: function(request) {
            return Axios.post(process.env.VUE_APP_API_URL + `/map/generate`, request);
        },

        getParkStatus: function(taskId) {
            return Axios.get(process.env.VUE_APP_API_URL + `/map/getResultStatus?taskId=` + taskId)
        },

        getParkResult: function(taskId) {
            return Axios.get(process.env.VUE_APP_API_URL + `/map/getResult?taskId=` + taskId)
        },

        getParkTEP: function(taskId) {
            return Axios.get(process.env.VUE_APP_API_URL + `/map/getEconomyParams?taskId=` + taskId)
        },



        getColorForObjectTag(objectTag) {
            switch (objectTag) {
                case "ART": return "#FCEC6D"
                case "EXHIBITION": return "#DE83FE"
                case "TRADE": return "#FD9191"
                case "FOOD": return "#FD9191"
                case "REST": return "#8AECA0"
                case "CHILDREN": return "#FFC38C"
                case "BICYCLE": return "#C7CDFD"
                case "SPORTS": return "#FE9DF4"
                case "PARKING": return "#8E9AF7"
                case "WATER": return "#6AEBFD"
                case "ANIMALS": return "#FE9DF4"
                case "AMUSEMENT": return "#DE83FE"
                case "GREENERY": return "#CDEC8A"
                default: return "#ff0000"
            }
        },

        getTextForObjectTag(objectTag) {
            switch (objectTag) {
                case "ART": return "МАФ"
                case "EXHIBITION": return "Культурные объекты"
                case "TRADE": return "Торговля"
                case "FOOD": return "Еда"
                case "REST": return "Тихий отдых"
                case "CHILDREN": return "Дети"
                case "BICYCLE": return "Велоинфраструктура"
                case "SPORTS": return "Спорт"
                case "PARKING": return "Парковки"
                case "WATER": return "Отдых у воды"
                case "ANIMALS": return "Для животных"
                case "AMUSEMENT": return "Аттракционы"
                case "GREENERY": return "Озеленение"
                default: return objectTag
            }
        },
    }
};

export default MapAPI