const FeatureStyles = {
    methods: {

        getObjectName(feature) {
            switch (feature.properties.type) {
                case "road":
                    return "Дорожка";
                case "greenery":
                    return "Озеленение";
                case "multifunctional":
                    return "Многофункциональная площадка";
                case "calm_rest":
                    return "Площадка тихого отдыха";
                case "playground":
                    return "Детская площадка";
                case "sports":
                    return "Спортивная площадка";
                case "dog_park":
                    return "Площадка для выгула собак";
                case "amusement_park":
                    return "Аттракционы";
                case "restaurant":
                    return "Ресторан";
                case "parking":
                    return "Парковка";
                case "bicycle_parking":
                    return "Велопарковка";
                case "entry_point":
                    return "Точка входа";
                case "fountain":
                    return "Фонтан";
                case "grass":
                    return "Газон";
            }
            return null
        }

    }
}

export default FeatureStyles