<template style="height: 100vh">
    <div id="app" class="d-flex flex-column" style="height:100vh ">
        <TopBar v-if="showTopBar"></TopBar>
        <div class="flex-grow-1">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import TopBar from './components/TopBar'

export default {
        name: 'App',
        components: {
            TopBar
        },
        computed: {
          showTopBar() {
            return this.$route.path !== "/about"
          }
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }
</style>
