<template>
    <MapComponent></MapComponent>
</template>

<script>
    import MapComponent from '../components/MapComponent'

    export default {
        name: "Map",
        components: {
            MapComponent
        }
    }
</script>

<style scoped>

</style>