<template>
    <div class="d-flex flex-column " style="margin: 2%">

      <div class="d-flex flex-row justify-content-between">
        <button @click="goToMap" class="btn-park-about-back">
              <img  src="../assets/icons/left_arrow.png">
            <div class="info-text-4">Назад к карте</div>
        </button>

        <img style="height: fit-content" src="../assets/icons/logo_itmo.png">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>

        <form action="https://urban.itmo.ru/hybrid_technology">
        <button type="submit" class="btn-park-about-learn-more">
            <div class="info-text-3">Узнать подробнее на сайте ИТМО</div>
            <img src="../assets/icons/right_arrow.png">
        </button>
        </form>

      </div>

      <div class="d-flex flex-row-reverse" style="height: 92px">
      </div>
      <div class="d-flex flex-row-reverse">
      <div class="about-text-title-frame">
        <div class="about-text-title">
          Гибридная <span class="about-green-background">&nbsp;технология&nbsp;</span> генерации проектов <span class="about-green-background">&nbsp;развития&nbsp;</span> <span class="about-green-background">&nbsp;территорий&nbsp;</span>
          <br>
        </div>
      </div>

      </div>
      <div class="d-flex flex-row-reverse" style="height: 92px">
      </div>

      <div class="d-flex flex-row justify-content-between">
        <div>
        </div>
        <div>
        </div>
        <div class="about-text-block-1">
          В России существует комплексная проблема качества разрабатываемых проектов благоустройства. В стране более тысячи городов, но на рынке присутствует крайне малое количество проектных бюро, обладающих достаточными компетенциями для разработки проектов, отвечающих истинным запросам жителей.
        </div>
        <div>
        </div>
      </div>

      <div class="d-flex flex-row-reverse" style="height: 52px">
      </div>

      <div class="d-flex flex-row justify-content-between">
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
      <div class="about-text-block-2">
        Для многих городов также остро стоит вопрос недостаточности финансирования и сроков подготовки проектов для того, чтобы провести качественные предпроектные исследования и оценить потребности будущих пользователей общественных пространств.
      </div>
        <div>
        </div>
      </div>

      <div class="d-flex flex-row-reverse" style="height: 92px">
      </div>

      <div class="d-flex flex-row justify-content-center">
      <b-img :src="require('../assets/About.jpg')" class="about-picture"></b-img>
      </div>

      <div class="d-flex flex-row-reverse" style="height: 52px">
      </div>

      <div class="d-flex flex-row justify-content-between">
        <div>
        </div>
      <div class="about-text-block-3">
        В рамках проекта Технологии сильного искусственного интеллекта в урбанистике и здравоохранении (Программа развития ИТМО – 2030) разработана гибридная технология генерации проектов развития территорий и демонстрирующий её MVP в виде программной библиотеки и публичного веб-сервиса.
      </div>
        <div>
        </div>
        <div>
        </div>
        <div>
        </div>
      </div>

      <div class="d-flex flex-row-reverse" style="height: 52px">
      </div>

      <div class="d-flex flex-row justify-content-between">
        <div>
        </div>
        <div>
        </div>
        <div class="d-flex flex-column">
      <div class="about-text-block-4">
        Решение обеспечивает агрегацию и семантический анализ пожеланий жителей на заданной территории, определяет оптимальное зонирование, размещает и связывает между собой различные элементы благоустройства, опираясь на выделенные запросы горожан и проектные рекомендации и нормативы.
      </div>
          <div class="d-flex flex-row-reverse" style="height: 42px">
          </div>
          <form action="https://urban.itmo.ru/hybrid_technology">
            <button type="submit" class="btn-park-about-learn-more-black">
              <div class="info-text-2">
                <span class="ml-2">Узнать подробнее на сайте ИТМО</span>
                <img src="../assets/icons/right_arrow.png">
              </div>
            </button>
          </form>
        </div>
        <div>
        </div>
      </div>

    </div>
</template>

<script>
    export default {
        name: "About",
      methods: {
        goToMap() {
          this.$router.push({
            name: 'map',
            params: {
            }
          })
        }
      }
    }
</script>

<style scoped>

    @import '../assets/styles/about.css';

    p {
        font-size: 14px;
        text-align: left
    }
    h3 {
        color: #000;
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        margin-bottom: 24px;
    }
</style>