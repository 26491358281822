import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import About from './pages/About.vue'
import Map from './pages/Map.vue'
import Editor from './pages/Editor'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';
import VueAnalytics from "vue-analytics";
import Result from "./pages/Result";
import LottieAnimation from "lottie-web-vue";

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(LottieAnimation)
Vue.use(VueRouter)

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const router = new VueRouter({
  routes:  [
    { path: '/', name:"map", component: Map },
    { path: '/about', component: About},
    { path: '/editor', name:"editor", component: Editor},
    { path: '/result', name:"result", component: Result}
  ]
});


// Configuration VueAnalytics
Vue.use(VueAnalytics, {
    id: 'UA-77562701-3',
    router
});


new Vue({
  router: router,
  render: h => h(App)
}).$mount('#app')