<template>
  <div class="d-flex flex-column mt-3" style="gap: 24px">
    <div class="very-large-title-text">
      {{getObjectName(selectedObject)}}
    </div>
    <div class="d-flex flex-row mb-2" style="gap: 10px">
      <div class="tep-primary d-flex flex-column justify-content-between" style="width: 228px">
        <div class="primary-tep-text text-left mb-2"> Общая площадь</div>
        <div class="primary-tep-value text-left">{{area}} м<sup>2</sup></div>
      </div>
      <div v-if="secondaryItemValue" class="tep-primary d-flex flex-column justify-content-between" style="width: 228px">
        <div class="primary-tep-text text-left mb-2">{{secondaryItemName}}</div>
        <div class="primary-tep-value text-left">{{secondaryItemValue}}</div>
      </div>
    </div>
    <div class="title-text">
      Учитывает пожелания:
    </div>
    <div class="d-flex flex-wrap">
      <div class="request-badge d-flex flex-row mr-2 mt-1" v-for="need of satisfiesNeeds" :key="need">
        <div class="request-circle" :style="{'background-color':  getColorForObjectTag(need)}"></div>
        <div class="request-text" style="height: 26px"> {{getTextForObjectTag(need)}}</div>
      </div>
    </div>

    <template v-if="imageForObject">
      <div class="title-text">
        Фотографии объекта:
      </div>
      <img :src="imageForObject">
    </template>
  </div>
</template>

<script>
import FeatureStyles from "@/mixins/FeatureStyles";

import activeImage from "@/assets/Active.jpg"
import attractionImage from "@/assets/Attraction.jpg"
import calmImage from "@/assets/Calm.jpg"
import cultureImage from "@/assets/Culture.jpg"
import sportImage from "@/assets/Sport.jpg"
import tradeImage from "@/assets/Trade.jpg"
import dogParkImage from "@/assets/DogPark.jpg"
import parkingImage from "@/assets/Parking.jpg"
import bicycleImage from "@/assets/bicycle.jpg"
import fountainImage from "@/assets/Fountain.jpg"
import MapAPI from "@/mixins/MapAPI";

export default {
  name: "ParkObjectComponent",
  props: ['selectedObject'],
  mixins: [
    FeatureStyles,
      MapAPI
  ],
  mounted() {
    console.log("Selected object properties " + this.selectedObject.properties)
  },
  computed: {
    area() {
      let area = this.objectTags["area"]
      if (area) {
        return area.toFixed(2)
      }
      return "0.00"
    },

    secondaryItemName() {
      return this.objectTags["capacity"] ? "Вместимость" : null
    },

    secondaryItemValue() {
      return this.objectTags["capacity"]
    },

    satisfiesNeeds() {
      let needs = this.objectTags["satisfyTags"]
      if (needs) {
        return needs.split(",")
      }
      return []
    },

    imageForObject() {
      switch (this.selectedObject.properties.type) {
        case "playground": return activeImage;
        case "sports": return sportImage;
        case "amusement_park": return attractionImage
        case "calm_rest": return calmImage;
        case "restaurant": return tradeImage;
        case "multifunctional": return cultureImage;
        case "dog_park": return dogParkImage;
        case "parking": return parkingImage;
        case "bicycle_parking": return bicycleImage;
        case "fountain": return fountainImage;
      }
      return null
    },

    objectTags() {
      return JSON.parse(this.selectedObject.properties["tags"])
    }
  }
}
</script>

<style scoped>
@import '../assets/styles/map.css';
@import '../assets/styles/editor.css';
@import '../assets/styles/viewer.css';
@import '../assets/styles/requests.css';
</style>