import Axios from '../axios'

const NLPAPI = {
    methods: {
        runSingleNLP: function(text) {
            let request = {
                texts: [text]
            }
            return Axios.post(process.env.VUE_APP_API_URL + `/nlp/parse`, request);
        }
    }
};

export default NLPAPI