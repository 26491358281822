import Axios from 'axios'
import LRUCache from 'lru-cache'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';

let axiosInstance = (() =>
    Axios.create({
        baseURL: '/',
        headers: { 'Cache-Control': 'no-cache' },
        adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(
            Axios.defaults.adapter,
            { enabledByDefault: false, defaultCache: new LRUCache({ maxAge: 1000*60, max: 100 })} //one-minute cache
        ))
    })
)();

export default axiosInstance;