<template>
  <div class="map-panel map-header-panel title-text">
    <b-link class="hidden-link" to="/">
      Гибридная технология генерации проектов развития территорий
    </b-link>
  </div>
</template>

<script>
export default {
  name: "TopBar"
}
</script>

<style scoped>
@import '../assets/styles/map.css';

.map-header-panel {
  position: absolute;
  width: 517px;
  height: 48px;
  left: 50%;
  top: 16px;

  transform: translate(-50%, 0);
}
</style>