<template class="flex-grow-1">

  <div style="height: 100%">
    <div v-if="loading" class="blur" style="position:absolute;width: 100vw; height: 100vh; z-index: 2010" >
      <b-card class="loading-card d-flex flex-column align-items-center justify-content-center">
        <lottie-animation :loop="true" :auto-play="true" :animationData="loadingAnimation"></lottie-animation>
        <div class="title-text text-center align-items-center justify-content-center">Идет генерация...</div>
        <div class="loading-timer-text text-center align-items-center justify-content-center">{{timeFromStart}}</div>
      </b-card>
    </div>
    <b-sidebar id="sidebar-1" title="Sidebar" left no-header visible sidebar-class="left-info-panel" width="512px" z-index="1001">
      <b-container class="pt-3"  style="background: #FEFEFE;" >
        <div class="d-flex flex-row justify-content-between">
          <button class="btn-park-outline" @click="$router.push('/')">
            <div class="info-text">
              <img src="../assets/icons/left_arrow.png">
              <span class="ml-2"> Выйти из режима создания территории</span>
            </div>
          </button>
          <img src="../assets/icons/logo_itmo.png">
        </div>

        <h1 class="very-large-title-text mt-5 mb-5">Новый парк</h1>

        <div class="editor-section mb-4">
          <div v-b-toggle.accordion-1 class="d-flex flex-row align-items-center p-3">
            <div class="when-open selected-index large-title-text mr-2">1</div>
            <div class="when-closed non-selected-index large-title-text mr-2">1</div>
            <div class="large-title-text flex-grow-1">Точки входа</div>
            <span class="when-open">  <img width="14" height="8" src="../assets/icons/up_arrow.png"></span>
            <span class="when-closed">  <img width="14" height="8" src="../assets/icons/down_arrow.png"></span>

          </div>
          <b-collapse id="accordion-1"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                      @show="addingEntryPoints = true; deletingEntryPoints = false;"
                      @hide="addingEntryPoints = false; deletingEntryPoints = false;"
          >
            <div class="m-3 entry-point-toggle-border d-flex flex-row align-items-center justify-content-around p-1">
              <div v-bind:class="addingEntryPoints ? 'entry-point-toggle-active': 'entry-point-toggle-inactive'" @click="addEntryPointsMode">Добавление точек</div>
              <div v-bind:class="deletingEntryPoints ? 'entry-point-toggle-active': 'entry-point-toggle-inactive'" @click="deleteEntryPointsMode">Удаление точек</div>
            </div>
            <div class="info-text m-3" v-if="addingEntryPoints">Последовательно отметьте точки входа на территорию парка. Точек входа не может быть меньше двух.</div>
            <div class="info-text m-3" v-if="deletingEntryPoints">Удалите точку входа, нажав на нее на карте.</div>
          </b-collapse>
        </div>

        <div class="editor-section">
          <div v-b-toggle.accordion-2 class="d-flex flex-row align-items-center p-3">
            <div class="when-open selected-index large-title-text mr-2">2</div>
            <div class="when-closed non-selected-index large-title-text mr-2">2</div>

            <div class="large-title-text flex-grow-1">Генерируемые объекты</div>
            <span class="when-open">  <img width="14" height="8" src="../assets/icons/up_arrow.png"></span>
            <span class="when-closed">  <img width="14" height="8" src="../assets/icons/down_arrow.png"></span>
          </div>

          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" >
            <div class="d-flex flex-column">
              <div class="info-text m-3">
                Выберите объекты, которые хотели бы добавить на территорию, или опишите пожелания в поле ниже, чтобы система их учла
              </div>

              <div class="d-flex flex-wrap m-3">
                <div class="request-badge d-flex flex-row mr-2 mt-1" v-bind:class="value > 0 ? 'request-badge-selected' : ''" v-for="[key, value] of Object.entries(this.parkTags)" :key="key">
                  <div class="request-circle" :style="{'background-color':  getColorForObjectTag(key)}"></div>
                  <div class="request-text"> {{getTextForObjectTag(key) + " x" + value}}</div>
                  <button v-if="value > 0" class="request-button" @click="deleteSuggestion(key)">-</button>
                  <button class="mr-1 request-button"  @click="addSuggestion(key)">+</button>
                </div>
              </div>

              <div class="enter-wishes m-3 text-left">
                Введите пожелания
              </div>

              <div class="ml-3 mr-3" >
                <b-form-textarea v-model="suggestionText" style="width: 100%; box-sizing: border-box"></b-form-textarea>
              </div>
              <div v-if="nlpRunning" class="ml-3 mt-1 gray-subtitle-text">Текст распознается, обычно это занимает несколько секунд...</div>
              <div v-if="nlpError" class="ml-3 mt-1 gray-subtitle-text" style="color: #FF114A">{{this.nlpError}}</div>

              <button class="btn-park-secondary title-text ml-3 mt-3" @click="runNlp" style="width: 40%">Распознать</button>

              <div v-if="nlpResult">
                <div class="d-flex flex-wrap m-3">
                  <div class="request-badge request-badge-selected d-flex flex-row mr-2 mt-1" v-for="res of nlpResult" :key="res.tag">
                    <div class="request-circle" :style="{'background-color':  getColorForObjectTag(res.tag)}"></div>
                    <div class="request-text" style="height: 26px"> {{getTextForObjectTag(res.tag)}}</div>
                  </div>
                </div>
                <div class="d-flex flex-row align-content-center m-3">
                  <button class="btn-park-secondary title-text mr-3 align-items-center justify-content-center" @click="addSuggestions">
                    Добавить распознанные теги
                  </button>
                  <button class="btn-park-outline title-text flex-fill  align-items-center justify-content-center" @click="clearSuggestions">
                    Сбросить всё
                  </button>
                </div>
              </div>





              <div class="or-text m-3">Или</div>

              <div class="info-text ml-3 mb-3 text-left">
                Загрузите CSV файл с точками, в котором будут столбцы "Текст", "Широта" и "Долгота"
              </div>
              <div v-if="!this.loading">
                <form enctype="multipart/form-data">
                  <input type="file" @change="onFileChange">
                </form>
                <hr>
              </div>



            </div>
          </b-collapse>
        </div>

        <b-button v-if="isReady" @click="generate" class="mt-2 mb-2 generate-button">Запустить генерацию</b-button>
        <span v-else v-b-tooltip.hover="disabledHint">
        <b-button  disabled class="mt-2 mb-2 generate-button" >Запустить генерацию</b-button>
        </span>

      </b-container>
    </b-sidebar>

    <editable-map ref="map"
                  editable
                  :zoom="zoom"
                  :maxZoom="18"
                  :center="center"
                  class="flex-grow-1"
                  :options="{attributionControl: false}"
                  @click="addEntryPoint"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-control-attribution position="bottomleft"></l-control-attribution>
      <l-geo-json :geojson="parkBounds" v-if="parkBounds" :options-style="{color: '#ff0000', fillOpacity: 0, dashArray: '10,5'}" pane="boundsPane"></l-geo-json>
      <l-geo-json :geojson="resultObjects" v-if="resultObjects" :options-style="styleFunction" :options="{onEachFeature: onEachFeature}" pane="objectsPane"></l-geo-json>
      <l-geo-json :geojson="resultRoads" v-if="resultRoads" :options-style="styleFunction" :options="{onEachFeature: onEachFeature}" pane="roadsPane"></l-geo-json>
      <l-control-scale position="bottomleft" :maxWidth="200" :imperial="false" :metric="true"></l-control-scale>
    </editable-map>

  </div>
</template>

<script>
import {geoJSON, latLng, marker} from "leaflet";
import {EditableMap} from "vue2-leaflet-editable";
import {LControlAttribution, LControlScale, LGeoJson, LTileLayer} from "vue2-leaflet";
import MapAPI from "@/mixins/MapAPI";
import NLPAPI from "@/mixins/NLPAPI";
import {parse} from 'csv-parse/lib/sync'
import Vue from "vue";
import loadingAnimation from "@/assets/animations/generating.json"
import LottieAnimation from "lottie-web-vue";

export default {
  name: "Editor",
  components: {
    EditableMap,
    LTileLayer,
    LControlAttribution,
    LControlScale,
    LGeoJson,
    LottieAnimation
  },
  mixins: [
    MapAPI,
    NLPAPI
  ],
  data() {
    return {
      parkBounds: null,
      parkTags: {},
      entryPointMarkers: [],
      resultObjects: null,
      resultRoads: null,

      loading: false,
      secondsPassed: 0,
      secondsInterval: null,
      loadingAnimation: loadingAnimation,

      addingEntryPoints: true,
      deletingEntryPoints: false,
      taskId: null,
      interval: null,
      zoom: 15,
      center: latLng(59.939468, 30.308769),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      nlpRunning: false,
      suggestionTags: [],
      suggestionText: "",
      nlpResult: null,
      nlpError: null,
      csvContent: '',
      csvEntries: [],
      suggestionOptions: [
        { value: "ART", text: this.getTextForObjectTag('ART')},
        { value: "EXHIBITION", text: this.getTextForObjectTag('EXHIBITION')},
        { value: "TRADE", text: this.getTextForObjectTag('TRADE')},
        { value: "FOOD", text: this.getTextForObjectTag('FOOD')},
        { value: "REST", text: this.getTextForObjectTag('REST')},
        { value: "CHILDREN", text: this.getTextForObjectTag('CHILDREN')},
        { value: "BICYCLE", text: this.getTextForObjectTag('BICYCLE')},
        { value: "SPORTS", text: this.getTextForObjectTag('SPORTS')},
        { value: "PARKING", text: this.getTextForObjectTag('PARKING')},
        { value: "WATER", text: this.getTextForObjectTag('WATER')},
        { value: "ANIMALS", text: this.getTextForObjectTag('ANIMALS')},
        { value: "AMUSEMENT", text: this.getTextForObjectTag('AMUSEMENT')},
        { value: "GREENERY", text: this.getTextForObjectTag('GREENERY')},
      ]
    }
  },

  mounted() {
    this.parkBounds = this.$route.params.parkGeometry
    this.parkTags = this.$route.params.parkTags
    for (let opt of this.suggestionOptions) {
      if (!this.parkTags[opt.value]) {
        Vue.set(this.parkTags, opt.value,  0);
      }
    }
    if (this.$route.params.entryPointMarkers){
      this.entryPointMarkers = this.$route.params.entryPointMarkers
      for (let entryMarker of this.$route.params.entryPointMarkers) {
        let self = this;
        let newMarker = entryMarker.addTo(this.$refs.map.mapObject).on("click", function (e){
          if (self.deletingEntryPoints === true) {
            self.$refs.map.mapObject.removeLayer(e.sourceTarget);
            let index = self.entryPointMarkers.indexOf(e.sourceTarget);
            if (index > -1) { // only splice array when item is found
              self.entryPointMarkers.splice(index, 1);
            }
          }
        });
        newMarker.bindPopup("Точка входа");
      }
    }
    let mapObject = this.$refs.map.mapObject
    mapObject.createPane("objectsPane")
    mapObject.createPane("roadsPane")
    mapObject.createPane("boundsPane")

    mapObject.getPane("boundsPane").style.zIndex = 200
    mapObject.getPane("roadsPane").style.zIndex = 300
    mapObject.getPane("objectsPane").style.zIndex = 400

    let g = geoJSON({type: "FeatureCollection", features: [this.parkBounds]});
    mapObject.fitBounds(g.getBounds())

    mapObject.on("click", function() {
      mapObject.editTools.stopDrawing()
    })
  },

  methods: {
    addEntryPointsMode() {
      if (this.addingEntryPoints === false) {
        this.addingEntryPoints = true;
        this.deletingEntryPoints = false;
      } else {
        this.addingEntryPoints = false;
        this.deletingEntryPoints = false;
      }
    },
    deleteEntryPointsMode(){
      if (this.deletingEntryPoints === false) {
        this.deletingEntryPoints = true;
        this.addingEntryPoints = false;
      } else {
        this.deletingEntryPoints = false;
        this.addingEntryPoints = false;
      }
    },
    addEntryPoint(e) {
      if (this.addingEntryPoints === true) {
        let self = this;
        let newMarker = new marker(e.latlng).addTo(this.$refs.map.mapObject).on("click", function (e){
          if (self.deletingEntryPoints === true) {
            self.$refs.map.mapObject.removeLayer(e.sourceTarget);
            let index = self.entryPointMarkers.indexOf(e.sourceTarget);
            if (index > -1) { // only splice array when item is found
              self.entryPointMarkers.splice(index, 1);
            }
          }
        });
        newMarker.bindPopup("Точка входа");
        this.entryPointMarkers.push(newMarker)
      }
    },

    runNlp() {
      if (!this.suggestionText || this.suggestionText.length === 0) {
        return
      }
      this.nlpRunning = true
      this.runSingleNLP(this.suggestionText).then(rz => {
        this.nlpRunning = false;
        if (rz.data.code === 0) {
          if (rz.data.results.length < 1) {
            console.error("Received empty NLP response")
            this.nlpЕrror = "Не удалось распознать ни одно пожелание в данном тексте"
            return;
          }
          this.nlpResult = rz.data.results[0].items
          this.suggestionTags = rz.data.results[0].items.map(i => i.tag)
        } else {
          this.nlpError = "Не удалось загрузить результат: " + rz.data.code + " " + rz.data.description
          if (this.nlpЕrror.length > 100) {
            this.nlpЕrror = this.nlpError.substring(0, 100)
          }
        }
      })
    },

    addSuggestions() {
      for (let tag of this.suggestionTags) {
        let count = this.parkTags[tag]
        if (!count) {
          count = 0
        }
        count++
        this.parkTags[tag] = count
      }
      this.clearSuggestions();
    },

    clearSuggestions() {
      this.suggestionTags = []
      this.nlpResult = null
      this.nlpError = null
      this.suggestionText = ""
    },

    addSuggestion(suggestion) {
      let count = this.parkTags[suggestion]
      if (!count) {
        count = 0
      }
      Vue.set(this.parkTags, suggestion, count + 1)
    },

    deleteSuggestion(suggestion) {
      let count = this.parkTags[suggestion]
      if (count) {
        count--
        if (count >= 0) {
          Vue.set(this.parkTags, suggestion, count)
        }
      }
    },

    generate() {
      this.addingEntryPoints = false;
      this.deletingEntryPoints = false;
      this.loading = true;
      this.secondsPassed = 0;
      this.secondsInterval = setInterval(() => {
        this.secondsPassed++;
      }, 1000)
      let request = {
        tags: [],
        parkGeoJson: "",
        locationRequests: this.csvEntries
      }
      for (let [key, value] of Object.entries(this.parkTags)) {
        for (let i = 0; i < value; ++i) {
          request.tags.push(key)
        }
      }

      let parkFeatureCollection = {
        type:"FeatureCollection",
        features: []
      }
      this.parkBounds.properties = { type : "boundary" }
      parkFeatureCollection.features.push(this.parkBounds)
      for (let marker of this.entryPointMarkers) {
        let markerFeature = marker.toGeoJSON();
        markerFeature.properties = {type: "entry"}
        parkFeatureCollection.features.push(
            markerFeature
        )
      }
      request.parkGeoJson = JSON.stringify(parkFeatureCollection)
      this.generatePark(request).then(rz => {
        if (rz.status === 200) {
          this.taskId = rz.data.taskId
          this.interval = setInterval(this.queryParkResult, 3000)
        } else {
          this.$bvToast.toast("Произошла ошибка " + rz.status, {
            title: 'Error',
            autoHideDelay: 5000,
            variant: "danger"
          });
        }
      })
    },

    queryParkResult() {
      this.getParkStatus(this.taskId).then(rz => {
        if (rz.status === 200) {
          if (rz.data.status === -1) {
            this.$bvToast.toast("Произошла ошибка " + rz.status, {
              title: 'Error',
              autoHideDelay: 5000,
              variant: "danger"
            });
            clearInterval(this.interval)
            this.loading = false;
          } else if (rz.data.status === 1) {
            clearInterval(this.interval)
            this.getParkResult(this.taskId).then(rz => {
              this.resultObjects = {
                type: "FeatureCollection",
                features:  rz.data.features.filter(value => value.properties.type !== "road" && value.properties.type !== "border")
              }
              this.resultRoads = {
                type: "FeatureCollection",
                features: rz.data.features.filter(value => value.properties.type === "road")
              }
              this.loading = false;
              this.goToResult();
            })
          }
        }
      })
    },

    styleFunction(feature) {
      switch (feature.properties.type) {
        case "road": return {
          fillColor: "#aaaaaa",
          color: "#565656",
          fillOpacity: 1,
          weight: 1
        };

        case "greenery":  return {
          fillColor: "#02a41f",
          color: "#045e14",
          fillOpacity: 1
        };
        case "multifunctional": return {
          fillColor: "#4fb8f1",
          color: "#0a0de1",
          fillOpacity: 1
        };
        case "calm_rest":  return {
          fillColor: "#206c30",
          color: "#626161",
          fillOpacity: 1
        };

        case "playground":  return {
          fillColor: "#f1af05",
          color: "#626161",
          fillOpacity: 1
        };

        case "sports":  return {
          fillColor: "#d73636",
          color: "#626161",
          fillOpacity: 1
        };

        case "dog_park":  return {
          fillColor: "#a6990c",
          color: "#626161",
          fillOpacity: 1
        };

        case "border": return {
          fillOpacity: 0,
          color: "#ff0000",
          dashArray: "10,5"
        };

        case "grass": return {
          fillColor: "#94e6a4",
          color: "#17942b",
          fillOpacity: 1
        }
      }
    },

    onEachFeature(feature, layer) {
      let popupText = null
      switch (feature.properties.type) {
        case "road":
          popupText = "Дорожка";
          break;
        case "greenery":
          popupText = "Озеленение";
          break;
        case "multifunctional":
          popupText = "Многофункциональная площадка";
          break;
        case "calm_rest":
          popupText = "Площадка тихого отдыха";
          break;
        case "playground":
          popupText = "Детская площадка";
          break;
        case "sports":
          popupText = "Спортивная площадка";
          break;
        case "dog_park":
          popupText = "Площадка для выгула собак";
          break;
        case "grass":
          popupText = "Газон";
          break;
      }
      if (popupText) {
        layer.bindPopup(popupText)
      }
    },

    goToResult() {
      this.$router.push({
        name: 'result',
        params: {
          taskId: this.taskId,
          parkBounds: this.parkBounds,
          parkTags: this.parkTags,
          resultObjects: this.resultObjects,
          resultRoads: this.resultRoads,
          entryPointMarkers: this.entryPointMarkers
        }
      })
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createInput(files[0]);
    },
    createInput(file) {
      let reader = new FileReader();
      let context = this;
      reader.onload = function() {
        context.csvContent = reader.result;
        const records = parse(context.csvContent, {
          columns: true,
          delimiter: ",",
          skip_empty_lines: true
        });
        context.csvEntries = records.map((entry) => { return {
          lat: entry["Широта"],
          lon: entry["Долгота"],
          text: entry["Текст"]
        }
        })
      }
      reader.readAsText(file);
    },
  },

  computed: {
    isReady() {
      return this.entryPointMarkers.length >= 2 && !this.loading;
    },
    timeFromStart() {
      let minutes = Math.floor(this.secondsPassed / 60)
      let seconds = this.secondsPassed % 60

      if (minutes < 10) {
        minutes = "0" + minutes.toString()
      } else {
        minutes = minutes.toString()
      }
      if (seconds < 10) {
        seconds = "0" + seconds.toString()
      } else {
        seconds = seconds.toString()
      }
      return minutes + ":" + seconds
    },
    disabledHint() {
      if (this.entryPointMarkers.length < 2) {
        return "Добавьте минимум две точки входа"
      }
      if (this.loading) {
        return "Дождитесь окончания генерации"
      }
      return ""
    }
  }
}
</script>

<style scoped>
@import '../assets/styles/map.css';
@import '../assets/styles/editor.css';
@import '../assets/styles/requests.css';

.blur {
  backdrop-filter: blur(3px);
}

.loading-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 16px 16px;
  gap: 16px;

  position: absolute;
  width: 219.16px;
  height: 321.16px;
  left: 50%;
  top: 295.81px;

  background: #FEFEFE;
  /* Shadows */

  box-shadow: 0px 6px 16px rgba(212, 218, 220, 0.64);
  backdrop-filter: blur(3px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 28px;

  transform: translate(-50%, 0);

}

.loading-timer-text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 70px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Black 2 */

  color: #2D2D2D;
}
</style>